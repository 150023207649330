import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DolphinBrand from '../../img/dolphin.svg'
import MineralSwimBrand from '../../img/Logo_MineralSwim_Stacked.svg'
import DolphinM600 from '../../img/M600.png'
import FusionKit from '../../img/ProductImage_MineralSwim_Fusion_NewPool_NoMirror.png'

const ProductStockist = props => {
	const { prettyStoreUrl, storeCountry } = props
	return (
		<div className="ProductStockist">
			<div className="stockist">
				<div className="banner">
					<div className="robot">
						<figure className="image is-128x128">
							<img
								className="is-rounded"
								src={DolphinM600}
								alt="Stockist of the Dolphin M Series"
								title="Stockist of the Dolphin M Series"
							/>
						</figure>
					</div>
					<div className="details">
						<h1>Pool Cleaner</h1>
						<div className="branding">
							<div className="dolphin">
								<img
									src={DolphinBrand}
									alt="It's A Dolphin"
									title="It's A Dolphin"
								/>
							</div>
							<div className="series">
								<div className="tags has-addons" style={{ flexWrap: 'nowrap' }}>
									<span className="tag is-white letter">M</span>
									<span className="tag is-primary series">Line</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="links">
					<div className="link">
						<a
							href="https://youtu.be/dW9gyUbHYwc"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="icon">
								<FontAwesomeIcon
									className="has-text-danger"
									icon={['fab', 'youtube']}
								/>
							</span>
							&nbsp;
							<strong>M Line</strong> Dolphin on YouTube
						</a>
					</div>
					<div className="link">
						<a
							href="https://mydolphin.com.au/product/dolphin-m-600/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="icon">
								<FontAwesomeIcon
									className="has-text-success"
									icon="shopping-bag"
								/>
							</span>
							&nbsp;More Info at MyDolphin
						</a>
					</div>
				</div>
			</div>
			<div className="stockist">
				<div className="banner">
					<div className="robot">
						<figure className="image is-128x128">
							<img
								className="is-rounded"
								src="https://images.ctfassets.net/ftcnv5up9dxv/7cB3FgXwayW9v9F1R7oLuT/2561b617c07ecdcab6e74abc4ac97ea6/ProductImage_MineralSwim_Ozone_1000_2000_3000_Series.png?fm=png&fl=png8&fit=pad&w=128&h=128"
								alt="Stockist of the Mineral Swim Fusion Line"
								title="Stockist of the Mineral Swim Fusion Line"
							/>
						</figure>
					</div>
					<div className="details">
						<h1>Water Treatment</h1>
						<div className="branding">
							<div className="dolphin">
								<img
									src={MineralSwimBrand}
									alt="Mineral Swim"
									title="Mineral Swim"
								/>
							</div>
							<div className="series">
								<div className="tags has-addons" style={{ flexWrap: 'nowrap' }}>
									<span
										className="tag is-white letter"
										style={{ width: '6.5rem' }}
									>
										Fusion
									</span>
									<span className="tag is-primary series">Line</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="links">
					<div className="link">
						<a
							href="https://youtu.be/jOX2c96N4so"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="icon">
								<FontAwesomeIcon
									className="has-text-danger"
									icon={['fab', 'youtube']}
								/>
							</span>
							&nbsp; Mineral Swim on YouTube
						</a>
					</div>
					<div className="link">
						<a
							href="https://maytronics.com.au/product/mineral-swim-fusion-series-iii/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="icon">
								<FontAwesomeIcon
									className="has-text-success"
									icon="shopping-bag"
								/>
							</span>
							&nbsp;More Info at Maytronics
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

ProductStockist.propTypes = {
	prettyStoreUrl: PropTypes.string,
	storeCountry: PropTypes.string,
}

export default ProductStockist
