import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DolphinService from '../../img/dolphin-service.jpg'

const RepairAgent = props => {
	return (
		<div className="RepairAgent">
			<h3>
				<FontAwesomeIcon icon="wrench" /> Customer Satisfaction Guarantee
			</h3>
			<div className="box">
				<div
					className="background"
					style={{
						transform: 'scaleX(-1)',
						background:
							'url(https://images.ctfassets.net/ftcnv5up9dxv/6Vkh2OnEsCz9YKQIdgNSPW/a0578b2270b59550040fb782a1390251/ozone-pure-full.jpg?w=800&h=600&q=60&fit=fill&f=top) 0% 0% / cover no-repeat',
						backgroundRepeat: 'no-repeat',
					}}
				/>
				<div className="gradient" />
				<img
					className="repairImg"
					src="https://images.ctfassets.net/ftcnv5up9dxv/6Vkh2OnEsCz9YKQIdgNSPW/a0578b2270b59550040fb782a1390251/ozone-pure-full.jpg?w=800&h=600&q=60&fit=fill&f=top"
					alt="Customer Satisfaction Guarantee"
				/>
				<div className="content">
					Customer Care
					<br />
					<b>Guarantee</b>
				</div>
			</div>
			<p>
				{props.shopName} are a professional pool builder, authorised and trained
				by Maytronics to provide exceptional customer satisfaction guaranteed!
				Experience ongoing peace of mind; knowing your new pool was built by an
				Elite builder who uses products of the highest industry standard.
			</p>
		</div>
	)
}

RepairAgent.propTypes = {
	shopName: PropTypes.string,
}

export default RepairAgent
