import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import EliteMaytronics from '../../img/Logo_Elite_Maytronics.svg'
import m500 from '../../img/m500.jpg'

const Banner = props => {
	const {
		shopWebsite,
		shopWebsite2,
		staticMap,
		shopEmail,
		formattedPhone,
		shopName,
		directMapsUrl,
		localityName,
	} = props
	return (
		<section className="Banner hero">
			<div className="gradient" />
			<div
				className="map"
				style={{
					backgroundImage: `url(https://images.ctfassets.net/ftcnv5up9dxv/5tNh5h4KbAJmQ9Fvoyp55v/cedd4721a07a389e3b6ad176d5dfa0bb/MineralSwim-Banner.jpg?w=1920&h=600&q=60&fit=fill&f=bottom)`,
				}}
			/>
			<svg
				width="100%"
				height="47px"
				viewBox="0 0 1440 47"
				className="wave"
				preserveAspectRatio="none"
			>
				<g id="Page-1" stroke="none" fill="none" fillRule="evenodd">
					<path
						d="M0,0.0577405639 C117.504588,18.44359 269.602477,22.1720696 456.293666,11.2431795 C562.076057,5.05068514 730.784198,0.911127653 885.297232,3.27366179 C1157.17617,7.43074321 1386.98062,21.3276838 1440,38.3891927 L1440,46.9388979 L0,46.9388979 L0,0.0577405639 Z"
						fill="#FFFFFF"
					/>
				</g>
			</svg>
			<div className="hero-body">
				<div className="container">
					<div className="field is-grouped is-hidden-dolphin-by-maytronics is-hidden-desktop">
						<p className="control">
							<span>Your Local</span>
						</p>
						<p className="control">
							<img src={EliteMaytronics} className="dolphin-logo" />
						</p>
						<p className="control">
							<b>Builder</b>
						</p>
					</div>
					<p className="is-dolphin-by-maytronics has-text-centered">
						<span>Your Local </span>
						<b>Elite Builder </b>
					</p>
					<div className="buttons" style={{ justifyContent: 'center' }}>
						{formattedPhone ? (
							<a className="button is-medium" href={`tel:${formattedPhone}`}>
								<span className="icon has-text-primary">
									<FontAwesomeIcon icon="phone" />
								</span>
								<span className="is-hidden-mobile">{formattedPhone}</span>
								<span className="is-hidden-tablet">Call Us</span>
							</a>
						) : (
							''
						)}
						{shopEmail ? (
							<a className="button is-medium" href={`mailto:${shopEmail}`}>
								<span className="icon has-text-primary">
									<FontAwesomeIcon icon="envelope" />
								</span>
								<span className="is-hidden-mobile">{shopEmail}</span>
								<span className="is-hidden-tablet">Email Us</span>
							</a>
						) : (
							''
						)}
					</div>
					<h1>{shopName}</h1>
					{shopWebsite ? (
						<a
							target="_blank"
							rel="nofollow noopener noreferrer"
							href={shopWebsite}
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'center',
								marginBottom: '0.5rem',
							}}
						>
							<span className="icon has-text-primary">
								<FontAwesomeIcon icon="star" />
							</span>
							<span>{shopWebsite}</span>
						</a>
					) : (
						''
					)}
					{shopWebsite2 ? (
						<a
							target="_blank"
							rel="nofollow noopener noreferrer"
							href={shopWebsite2}
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'center',
								marginBottom: '0.5rem',
							}}
						>
							<span className="icon has-text-primary">
								<FontAwesomeIcon icon="star" />
							</span>
							<span>{shopWebsite2}</span>
						</a>
					) : (
						''
					)}
					{localityName ? (
						<a
							target="_blank"
							rel="nofollow noopener noreferrer"
							href={directMapsUrl}
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'center',
							}}
						>
							<span>Pool Builder at</span>
							<span className="icon has-text-primary">
								<FontAwesomeIcon icon="map-marker" />
							</span>
							<span>{localityName ? localityName : 'No Address'}</span>
						</a>
					) : (
						''
					)}
				</div>
			</div>
		</section>
	)
}

Banner.propTypes = {
	staticMap: PropTypes.string,
	shopEmail: PropTypes.string,
	formattedPhone: PropTypes.string,
	shopName: PropTypes.string,
	directMapsUrl: PropTypes.string,
	regionName: PropTypes.string,
}

export default Banner
