import React from 'react'
import PropTypes from 'prop-types'

import FreeDemo from './FreeDemo'
// import MoreInfo from './MoreInfo'
import { models, regions, conditions } from './data'

const EnquiryForm = props => {
	const {
		modalState,
		formName,
		openForm,
		shopName,
		shopEmail,
		shopAddress,
		storeCountry,
		relativeUrl,
	} = props
	let modelOptions
	let regionOptions
	let conditionOptions
	if (storeCountry === 'NZ') {
		modelOptions = models.nz
		regionOptions = regions.nz
		conditionOptions = conditions.nz
	} else if (storeCountry === 'ZA') {
		modelOptions = models.za
		regionOptions = regions.za
		conditionOptions = conditions.za
	} else if (storeCountry === 'AU') {
		modelOptions = models.au
		regionOptions = regions.au
		conditionOptions = conditions.au
	}
	return (
		<div className="EnquiryForm">
			<FreeDemo
				modalState={modalState}
				formName={formName}
				openForm={openForm}
				shopName={shopName}
				shopEmail={shopEmail}
				shopAddress={shopAddress}
				storeCountry={storeCountry}
				regionOptions={regionOptions}
				modelOptions={modelOptions}
				conditionOptions={conditionOptions}
				relativeUrl={relativeUrl}
			/>
		</div>
	)
}

EnquiryForm.propTypes = {
	modalState: PropTypes.bool,
	formName: PropTypes.string,
	openForm: PropTypes.func,
	shopName: PropTypes.string,
	shopEmail: PropTypes.string,
	storeCountry: PropTypes.string,
	relativeUrl: PropTypes.string,
}

export default EnquiryForm
