import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import EliteMaytronics from '../../img/Logo_Elite_Maytronics.svg'

const Nav = props => {
	const {
		logo,
		url,
		affiliate,
		// localStoreInRegion,
		openForm,
		ecommerceStoreLink,
		storeCountry,
	} = props
	return (
		<header className="Nav">
			<div className="container">
				<nav className="navbar">
					<div className="navbar-brand">
						<a
							href={url ? url : '#'}
							target={url ? `_blank` : ``}
							rel={url ? `noopener noreferrer` : ``}
							className="navbar-item"
						>
							<img src={logo} className="logo" />
						</a>
						<div
							className="navbar-item is-hidden-desktop"
							style={{ marginLeft: 'auto' }}
						>
							<div className="buttons">
								<button
									onClick={() => openForm('free-demo')}
									className="button is-dark is-outlined"
								>
									<span className="icon">
										<FontAwesomeIcon icon="comment" />
									</span>
									<span className="is-hidden-micro">Quote my Pool</span>
									<span className="is-micro">Quote</span>
								</button>
								<button
									onClick={() => openForm('more-info')}
									className="button is-warning"
								>
									<span className="icon">
										<FontAwesomeIcon icon="info-circle" />
									</span>
									<span className="is-hidden-micro">More Info</span>
									<span className="is-micro">Info</span>
								</button>
							</div>
						</div>
					</div>
					<div className="navbar-menu">
						<div className="navbar-start">
							<div className="navbar-item tagline">
								<div className="field is-grouped">
									<p className="control">
										<span>Your Local</span>
									</p>
									<p className="control">
										<img src={EliteMaytronics} className="dolphin-logo" />
									</p>
									<p className="control">
										<b>Builder</b>
									</p>
								</div>
							</div>
						</div>
						<div className="navbar-end">
							<div className="navbar-item">
								<div className="buttons">
									<button
										onClick={() => openForm('free-demo')}
										className="button is-dark is-outlined"
									>
										<span className="icon">
											<FontAwesomeIcon icon="comment" />
										</span>
										<span>Quote my Pool</span>
									</button>
									<button
										onClick={() => openForm('more-info')}
										className="button is-warning"
									>
										<span className="icon">
											<FontAwesomeIcon icon="info-circle" />
										</span>
										<span>More Info</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
		</header>
	)
}

Nav.propTypes = {
	url: PropTypes.string,
	affiliate: PropTypes.string,
	logo: PropTypes.string,
	localStoreInRegion: PropTypes.string,
	openForm: PropTypes.func,
	ecommerceStoreLink: PropTypes.string,
	storeCountry: PropTypes.string,
}

export default Nav
